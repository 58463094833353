import { AppActionTypes, SWITCH_MENU_STATE } from "./actions";
import {produce} from "immer";

export interface State {
    readonly menuState: string;
}

const initialState : State = {
    menuState: ""
}

export function appReducer(state = initialState,
    action: AppActionTypes) : State
{
    let newState = produce(state, (draft) =>
    {
            switch(action.type)
            {
                case SWITCH_MENU_STATE:
                    draft.menuState=action.newState;
                    break;
            }
    });

    return newState;
}
