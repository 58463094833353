import React, {Component} from 'react';
import './App.css';
import {createStore, Dispatch} from "redux";
import {State, appReducer} from "./reducer";
import {connect, ConnectedProps} from "react-redux";
import {BrowserRouter, Switch,Route} from "react-router-dom";
import Login from './Login';
import Home from './Home';
import Logout from './Logout';
import ForgotPw from './ForgotPw';
import Account from './Account'

export const store = createStore(appReducer)

const mapStateToProps = (state: State) => {
  return {
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

class App extends Component<ConnectedProps<typeof connector> >
{
  render() {
    return (
      <BrowserRouter>
          <Switch>
            <Route path="/app/home">
              <Home />
            </Route>
            <Route path="/app/logout">
              <Logout />
            </Route>
            <Route path="/app/forgot_pw">
              <ForgotPw />
            </Route>
            <Route path="/app/account_settings">
              <Account />
            </Route>
            <Route path="/">
              <Login />
            </Route>
          </Switch>
      </BrowserRouter>
    );
  }
}

export default connector(App)