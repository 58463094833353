import { State } from "./reducer";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import React from "react";
import { Form, Card, Input, Button, Modal } from "antd";
import {Store} from "rc-field-form/lib/interface";
import { LockOutlined } from "@ant-design/icons";


const mapStateToProps = (state: State) => {
    return { 
    }
}
  
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

class ChangePw extends Component<RouteComponentProps & ConnectedProps<typeof connector> >
{
    state = {
        loading: false
    }

    handleSubmit = async (values: Store) => {
        var formData = new FormData();
        formData.append("old_password", values["old_password"]);
        formData.append("password", values["password1"]);
        
        this.setState({loading: true});
        try {
            const resp = await fetch("/api/change_password2",
                {method: "POST",
                    body: formData});

            var jdata = await resp.json();
        } catch(error) {
            jdata = {"err": "Connection issue with service"};
        }

        this.setState({loading: false});
        if("err" in jdata)
        {
            Modal.info(
            {
                title: "Error during password change request",
                content: (
                    <p>{jdata.err}</p>
                ),
                onOk: () => {}
            });
            return;
        }

        Modal.info(
            {
                title: "Password changed successfully",
                content: (
                    <p>The password was changed successfully to the new password</p>
                ),
                onOk: () => {}
            });
    }

    layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    render() {
        return (
            <Card title="Change password">
                <Form onFinish={this.handleSubmit} {...this.layout}>
                    <Form.Item rules={[
                        { required: true, message: 'Please input your old password!' },
                        {
                            async validator(rule, value) {
                                var formData = new FormData();
                                formData.append("password", value)
                                
                                try {
                                    const resp = await fetch("/api/check_pw",
                                        {method: "POST",
                                            body: formData});

                                    var jdata = await resp.json();
                                } catch(error) {
                                    jdata = {"err": "Connection issue with service"};
                                }

                                if(!("err" in jdata) && !jdata.ok) {
                                    jdata = {"err": "Error while checking password"};
                                }

                                if("err" in jdata)
                                {
                                    return Promise.reject(jdata["err"]);
                                }

                                if(!jdata.pw_ok) {
                                    return Promise.reject("Password wrong!");
                                }

                                return Promise.resolve();
                            }
                        }
                        ]} name="old_password" label="Current password">
                        <Input
                        type="password"
                        prefix={<LockOutlined />}
                        placeholder="Old password"/>
                    </Form.Item>
                    <Form.Item rules={[{ required: true, message: 'Please input your new password!' }]} name="password1" label="New password">
                        <Input
                        prefix={<LockOutlined />}
                        type="password"
                        placeholder="New password"/>
                    </Form.Item>
                    <Form.Item rules={[
                        { required: true, message: 'Please repeat your new password!' },
                        ({getFieldValue}) => ({
                            validator(rule, value) {
                                if(!value || getFieldValue("password1")===value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject("New passwords do not match");
                            }
                        })
                        ]} name="password2" label="Repeat new password">
                        <Input
                        prefix={<LockOutlined />}
                        type="password"
                        placeholder="Repeat new password"/>
                    </Form.Item>
                    <Form.Item {...this.tailLayout}>
                        <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.loading}>
                        Set new password
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        );
    }
}

export default withRouter(connector(ChangePw))