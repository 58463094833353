import React, {Component} from 'react';
import { PageHeader, Form, Input, Button, Modal, Spin} from 'antd';
import {UserOutlined, LockOutlined} from '@ant-design/icons';
import {Store} from "rc-field-form/lib/interface";
import { State } from './reducer';
import { Dispatch } from 'redux';
import { connect, ConnectedProps } from 'react-redux';
import { SWITCH_MENU_STATE } from './actions';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';

const mapStateToProps = (state: State) => {
    return {

    }
}
  
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        switchToHome: () => dispatch({type: SWITCH_MENU_STATE, newState: "home"})
    }
}
  
const connector = connect(mapStateToProps, mapDispatchToProps);

interface LoginState {
    loading: boolean
}

class Login extends Component<RouteComponentProps & ConnectedProps<typeof connector> >
{
    state: LoginState = {
        loading: false
    }

    handleSubmit = async (values: Store) =>
    {
        console.log("Values: ", values);
        console.log("Name: ", values["name"]);

        var formData = new FormData();
        formData.append("email", values["email"]);
        formData.append("password", values["password"])
        
        this.setState({loading: true})
        try {
            const resp = await fetch("/api/login",
                {method: "POST",
                    body: formData});

            var jdata = await resp.json();
        } catch(error) {
            jdata = {"err": "Connection issue with service"};
        }

        this.setState({loading: false})
        if("err" in jdata)
        {
            Modal.info(
            {
                title: "Error during login",
                content: (
                    <p>{jdata.err}</p>
                ),
                onOk: () => {}
            });
            return;
        }

        this.props.switchToHome();
        this.props.history.push("/app/home");
        console.log(jdata);
    }

    render() {
        const loginForm = (
        <Form onFinish={this.handleSubmit} className="login-form">
            <Form.Item rules={[{ required: true, message: 'Please input your e-mail!' }]} name="email">
                <Input
                prefix={<UserOutlined />}
                placeholder="E-mail"/>
            </Form.Item>
            <Form.Item rules={[{ required: true, message: 'Please input your password!' }]} name="password">
                <Input
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"/>
            </Form.Item>
            <Link to="/app/forgot_pw">Forgot password</Link>
                        
            <hr style={{visibility: "hidden"}}></hr>
            <Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button">
                Log in
                </Button>
            </Form.Item>
        </Form>
        );

        const loadingDisp = (
            <div style={{textAlign: "center"}}>
                <div>Logging in...</div>
                <hr style={{visibility: "hidden"}}/>
                <Spin />
            </div>
        );

        return (
            <div style={{ height: "100%"}}>
                <PageHeader title="Login" />
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80%" }}>
                    <div>
                    {!this.state.loading && loginForm}
                    {this.state.loading && loadingDisp}
                    </div>
                </div>
            </div>
        )
    }

    /*async componentDidMount() {
        await this.handleSubmit({"email": "martin@urbackup.org", "password": "saa7134_b"});
    }*/
}

export default withRouter(connector(Login))