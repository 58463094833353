import { State } from "./reducer";
import { Dispatch } from "redux";
import { SWITCH_MENU_STATE } from "./actions";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ConnectedProps, connect } from "react-redux";
import React from "react";
import { Spin, Modal, PageHeader } from "antd";

const mapStateToProps = (state: State) => {
    return {

    }
}
  
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        switchToLogin: () => dispatch({type: SWITCH_MENU_STATE, newState: ""})
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

class Logout extends Component<RouteComponentProps & ConnectedProps<typeof connector> >
{
    render() {
        return (
            <div style={{ height: "100%"}}>
                <PageHeader title="Logout" />
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "90%", flexFlow: "wrap"}}>
                    <div style={{textAlign: "center"}}>
                        <div>Logging out...</div>
                        <hr style={{visibility: "hidden"}}/>
                        <Spin />
                    </div>
                </div>
            </div>
        );
    }

    async componentDidMount() {
        const resp = await fetch("/api/logout",
            {method: "POST"});

        var jdata = await resp.json();

        if(!("err" in jdata) && !jdata.ok)
            jdata.err="Error logging out";

        if("err" in jdata)
        {
            Modal.info(
            {
                title: "Error during logout",
                content: (
                    <p>{jdata.err}</p>
                ),
                onOk: () => {}
            });
            return;
        }

        this.props.switchToLogin();
        this.props.history.push("/app/");
    }
}

export default withRouter(connector(Logout));