import { State } from "./reducer";
import { Dispatch } from "redux";
import { Component } from "react";
import { ConnectedProps, connect } from "react-redux";
import React from "react";
import { CheckCircleTwoTone, CloseCircleOutlined, CheckCircleFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Table, Modal, message } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import { SWITCH_MENU_STATE } from "./actions";
import { RouteComponentProps, withRouter } from "react-router-dom";

const mapStateToProps = (state: State) => {
    return {

    }
}
  
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        switchToLogin: () => dispatch({type: SWITCH_MENU_STATE, newState: ""})
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

interface TableData
{
    id: number,
    name: string,
    created: number,
    last_login: number,
    last_seen: number,
    redir: number,
    urlkey: string,
    todel: number,
    stopdel_loading: boolean
}

interface TableState {
    data: Array<TableData>,
    loading: boolean
}

class Appliances extends Component<RouteComponentProps & ConnectedProps<typeof connector> >
{
    state: TableState = {
        data: [],
        loading: false,
      };

    setStopDelLoading = (record: TableData, b: boolean) => {
        let data = [ ...this.state.data];

        record.stopdel_loading=b;
        data = data.map( (v: TableData) => {
            if (v.id === record.id)
                return record;
            else
                return v;
        });
        this.setState({data: data});
    }

    deleteAppliance = async (record: TableData) => {
        Modal.confirm({
            title: "Confirm deletion",
            icon: <ExclamationCircleOutlined />,
            content: ("Are you sure you really want to delete appliance "+record.name+"? All attached cloud drives will be scheduled for deletion as well."),
            onOk: async () => {
                var formData = new FormData();
                formData.append("device_id", ""+record.id);

                let jdata;
                try {
                    const resp = await fetch("/api/appliance",
                        {method: "DELETE", credentials: "include",
                        body: formData });

                    jdata = await resp.json();
                } catch(error) {
                    jdata = {"err": "Connection issue with service"};
                }

                if("err" in jdata
                    || !jdata.ok)
                {
                    if(!("err" in jdata))
                        jdata.err="Unknown error";

                    Modal.warn(
                    {
                        title: "Error while deleting appliance",
                        content: (
                            <p>{jdata.err}</p>
                        ),
                        onOk: () => {}
                    });
                    if(jdata.err==="No session") {
                        this.props.switchToLogin();
                        this.props.history.push("/app/");
                    }
                    return;
                }

                message.info("Scheduled appliance "+record.name+" for deletion");
                let data = [ ...this.state.data];

                record.todel=1;
                data = data.map( (v: TableData) => {
                    if (v.id === record.id)
                        return record;
                    else
                        return v;
                });
                this.setState({data: data});
            }
        });
    }

    stopDeleteAppliance = async (record: TableData) => {
        this.setStopDelLoading(record, true);
        var formData = new FormData();
        formData.append("device_id", ""+record.id);

        let jdata;
        try {
            const resp = await fetch("/api/appliance_stop_deletion",
                {method: "POST", credentials: "include",
                body: formData });

            jdata = await resp.json();
        } catch(error) {
            jdata = {"err": "Connection issue with service"};
        }

        if("err" in jdata
            || !jdata.ok)
        {
            if(!("err" in jdata))
                jdata.err="Unknown error";

            Modal.warn(
            {
                title: "Error while stopping appliance deletion",
                content: (
                    <p>{jdata.err}</p>
                ),
                onOk: () => {}
            });
            if(jdata.err==="No session") {
                this.props.switchToLogin();
                this.props.history.push("/app/");
            }
            this.setStopDelLoading(record, false);
            return;
        }

        let data = [ ...this.state.data];

        record.todel=0;
        record.stopdel_loading=false;
        data = data.map( (v: TableData) => {
            if (v.id === record.id)
                return record;
            else
                return v;
        });
        this.setState({data: data});
    }

    async componentDidMount() {
        this.setState({loading: true});

        const resp = await fetch("/api/get_appliances",
            {method: "GET", credentials: "include"});
        
        const jdata = await resp.json();
        
        if("err" in jdata
            || !jdata.ok)
        {
            if(!("err" in jdata))
                jdata.err="Unknown error";

            Modal.info(
            {
                title: "Error while fetching data",
                content: (
                    <p>{jdata.err}</p>
                ),
                onOk: () => {}
            });
            if(jdata.err==="No session") {
                this.props.switchToLogin();
                this.props.history.push("/app/");
            }
            return;
        }

        this.setState({
            loading: false,
            data: jdata.apps
        })
    }

    descendSortOrder = () : SortOrder => { return "descend" }

    columns = [
        {
          title: 'Id',
          dataIndex: 'id',
          sorter: (a: TableData, b: TableData) => a.id-b.id,
        },
        {
            title: "Online",
            dataIndex: "last_seen",
            sorter: (a: TableData, b: TableData) => a.last_seen-b.last_seen,
            defaultSortOrder: this.descendSortOrder(),
            render: (last_seen: number) => {
                var ctime = (new Date()).getTime() / 1000;
                if(ctime-last_seen<2*60*60) {
                    return <CheckCircleFilled  style={{color: "#52c41a"}} />;
                } else {
                    return <CloseCircleOutlined />;
                }
            }
        },
        {
          title: 'Appliance name',
          dataIndex: 'name',
          sorter: (a: TableData, b: TableData) => a.name.localeCompare(b.name),
        },
        {
          title: 'Created at',
          dataIndex: 'created',
          render: (created: number) => new Date(created*1000).toLocaleString(), 
          sorter: (a: TableData, b: TableData) => a.created-b.created,
        },
        {
            title: 'Last login',
            dataIndex: 'last_login',
            render: (last_login: number) => new Date(last_login*1000).toLocaleString(),
            sorter: (a: TableData, b: TableData) => a.last_login-b.last_login,
        },
        {
            title: 'Last seen',
            dataIndex: 'last_seen',
            render: (last_seen: number) => new Date(last_seen*1000).toLocaleString(),
            sorter: (a: TableData, b: TableData) => a.last_seen-b.last_seen,
        },
        {
            title: "Actions",
            render: (text: string, record: TableData) => {

                let delBtn: JSX.Element;

                if(record.todel>0)
                {
                    delBtn = <span>
                        Scheduled to be deleted
                        <Button onClick={(e) => {this.stopDeleteAppliance(record)}} style={{marginLeft: "5pt"}} loading={record.stopdel_loading}>Stop deletion</Button>
                    </span>
                }
                else 
                {
                    delBtn = <Button danger onClick={(e) => {this.deleteAppliance(record)}}>Delete</Button>
                }

                return (<span>
                        {record.redir>0 && 
                            <a href={"https://app.urbackup.com/"+record.name+"_"+record.urlkey+"/"} style={{marginRight: "5pt"}} target="_blank" rel="noopener noreferrer">Go to appliance</a>
                        }
                        {delBtn}
                        </span>);
            }
        }
      ];

    render() {
        return (
            <div style={{ height: "100%"}}>
                <Table
                    columns={this.columns}
                    rowKey={record => record.id.toString()}
                    dataSource={this.state.data}
                    loading={this.state.loading}
                    pagination={{position: "both", showSizeChanger: true, defaultPageSize: 50}}
                    title={() => "Appliances"}/>
            </div>
        );
    }
}

export default withRouter(connector(Appliances))