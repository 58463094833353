import React, {Component} from 'react';
import { State } from "./reducer";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import MainMenu from './MainMenu';
import Clouddrives from './Clouddrives';
import { Layout } from 'antd';
import Appliances from './Appliances';
import Keys from './Keys';


const mapStateToProps = (state: State) => {
    return {

    }
}
  
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
    }
}
  
const connector = connect(mapStateToProps, mapDispatchToProps);

class Home extends Component<ConnectedProps<typeof connector> >
{
    render() {
        return (
            <div style={{ height: "100%"}}>
                <Layout.Sider style={{height: "100%", position: 'fixed', left: 0}}>
                    <MainMenu />
                </Layout.Sider>
                <Layout className="site-layout" style={{ marginLeft: 200 }}>
                    <Layout.Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                        <Appliances />
                    </Layout.Content>
                    <Layout.Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                        <Clouddrives />
                    </Layout.Content>
                    <Layout.Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                        <Keys />
                    </Layout.Content>
                </Layout>
            </div>
        );
    }
}

export default connector(Home)