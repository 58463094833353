import { State } from "./reducer";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ChangePw from "./ChangePw";
import React from "react";
import { Layout } from "antd";
import MainMenu from "./MainMenu";


const mapStateToProps = (state: State) => {
    return {

    }
}
  
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

class Account extends Component<RouteComponentProps & ConnectedProps<typeof connector> >
{
    render() {
        return (
            <div style={{ height: "100%"}}>
                <Layout.Sider style={{height: "100%", position: 'fixed', left: 0}}>
                    <MainMenu />
                </Layout.Sider>
                <Layout className="site-layout" style={{ marginLeft: 200 }}>
                    <Layout.Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                        <ChangePw />
                    </Layout.Content>
                </Layout>
            </div>
            
        );
    }
}

export default withRouter(connector(Account))