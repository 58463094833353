import React, {Component} from 'react';
import { State } from "./reducer";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import {Menu} from "antd";
import { Link } from 'react-router-dom';
import { SWITCH_MENU_STATE } from './actions';
import { HomeOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';


const mapStateToProps = (state: State) => {
    return {
        menuState: state.menuState
    }
  }
  
const mapDispatchToProps = (dispatch: Dispatch) => {
return {
    switchMenuState: (newState: string) => dispatch({type: SWITCH_MENU_STATE, newState: newState})
}
}

const connector = connect(mapStateToProps, mapDispatchToProps);
  

class MainMenu extends Component<ConnectedProps<typeof connector> >
{
    render() {
        return (
            <div style={{ height: "100%"}}>
                <Menu mode="inline" style={{ width: 180, height: "100%" }} selectedKeys={[this.props.menuState]}>
                    <Menu.Item key="home"><Link to="/app/home" onClick={() => {this.props.switchMenuState("home")}}><HomeOutlined /> Home</Link></Menu.Item>
                    <Menu.Item key="account_settings"><Link to="/app/account_settings" onClick={() => {this.props.switchMenuState("account_settings")}}><UserOutlined /> Account</Link></Menu.Item>
                    <Menu.Item key="logout"><Link to="/app/logout" onClick={() => {this.props.switchMenuState("logout")}}><LogoutOutlined /> Logout</Link></Menu.Item>
                </Menu>
            </div>
        );
    }
}

export default connector(MainMenu)