import { State } from "./reducer"
import { SWITCH_MENU_STATE } from "./actions"
import { Dispatch } from "redux"
import { Component } from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { ConnectedProps, connect } from "react-redux"
import React from "react"
import { PageHeader, Form, Input, Modal, Spin, Button } from "antd"
import { UserOutlined } from "@ant-design/icons"
import {Store} from "rc-field-form/lib/interface";


const mapStateToProps = (state: State) => {
    return {

    }
}
  
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        switchToLogin: () => dispatch({type: SWITCH_MENU_STATE, newState: ""})
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ForgotPwState {
    loading: boolean
}

class ForgotPw extends Component<RouteComponentProps & ConnectedProps<typeof connector> >
{
    state: ForgotPwState = {
        loading: false
    }

    handleSubmit = async (values: Store) => {
        var formData = new FormData();
        formData.append("email", values["email"]);
        
        this.setState({loading: true});
        try {
            const resp = await fetch("/api/reset_account_password2",
                {method: "POST",
                    body: formData});

            var jdata = await resp.json();
        } catch(error) {
            jdata = {"err": "Connection issue with service"};
        }

        this.setState({loading: false});
        if("err" in jdata)
        {
            Modal.info(
            {
                title: "Error during password reset request",
                content: (
                    <p>{jdata.err}</p>
                ),
                onOk: () => {}
            });
            return;
        }

        Modal.info(
            {
                title: "Password reset started",
                content: (
                    <p>An e-mail with further instructions to reset your account password has been sent to your e-mail address</p>
                ),
                onOk: () => {}
            });

        this.props.switchToLogin();
        this.props.history.push("/app/");
    }

    render() {
        const resetForm = (
            <div>
                Pease enter the e-mail address of your account
                <Form onFinish={this.handleSubmit} className="login-form">
                    <Form.Item rules={[{ required: true, message: 'Please input your e-mail!' }]} name="email">
                        <Input
                        prefix={<UserOutlined />}
                        placeholder="E-mail"/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Start account reset
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );

        const loadingDisp = (
            <div style={{textAlign: "center"}}>
                <div>Starting account reset...</div>
                <hr style={{visibility: "hidden"}}/>
                <Spin />
            </div>
        );

        return (
            <div style={{ height: "100%"}}>
                <PageHeader title="Reset account password" />
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "80%" }}>
                    {this.state.loading && loadingDisp}
                    {!this.state.loading && resetForm}
                </div>
            </div>
        )
    }
}

export default withRouter(connector(ForgotPw))