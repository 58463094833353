import { State } from "./reducer";
import { Dispatch } from "redux";
import { Component } from "react";
import { ConnectedProps, connect } from "react-redux";
import React from "react";
import { LockOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Table, Modal, Input, message } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import { SWITCH_MENU_STATE } from "./actions";
import { RouteComponentProps, withRouter } from "react-router-dom";

const mapStateToProps = (state: State) => {
    return {

    }
}
  
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        switchToLogin: () => dispatch({type: SWITCH_MENU_STATE, newState: ""})
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

interface TableData
{
    id: number,
    disk_id: string,
    created: number,
    enckey: string,
    decrypt_loading: boolean
}

interface TableState {
    data: Array<TableData>,
    loading: boolean
}

class Keys extends Component<RouteComponentProps & ConnectedProps<typeof connector> >
{
    state: TableState = {
        data: [],
        loading: false
      };

    decrypt_password: string = "";

    
    async componentDidMount() {
        this.setState({loading: true});

        const resp = await fetch("/api/storage_encryption_keys",
            {method: "GET", credentials: "include"});
        
        const jdata = await resp.json();
        
        if("err" in jdata
            || !jdata.ok)
        {
            if(!("err" in jdata))
                jdata.err="Unknown error";

            Modal.info(
            {
                title: "Error while fetching data",
                content: (
                    <p>{jdata.err}</p>
                ),
                onOk: () => {}
            });
            if(jdata.err==="No session") {
                this.props.switchToLogin();
                this.props.history.push("/app/");
            }
            return;
        }

        this.setState({
            loading: false,
            data: jdata.keys.map((v: TableData) => {
                v.enckey="";
                v.decrypt_loading=false;
                return v;
            })
        })
    }

    descendSortOrder = () : SortOrder => { return "descend" }

    setRevealLoading = (record: TableData, b: boolean) => {
        let data = [ ...this.state.data];

        record.decrypt_loading=b;
        data = data.map( (v: TableData) => {
            if (v.id === record.id)
                return record;
            else
                return v;
        });
        this.setState({data: data});
    }

    decryptEnckey = (record: TableData) => {
        this.setRevealLoading(record, true);
        this.decrypt_password="";
        
        Modal.info( {
            title: "Please enter the password to decrypt the key with",
            content: (
                <Input
                        prefix={<LockOutlined />}
                        type="password"
                        placeholder="Password"
                        onChange={(e) => {
                            this.decrypt_password=e.target.value
                        }
                        } />
            ),
            onOk: async () => {
                var formData = new FormData();
                formData.append("disk_id", record.disk_id);
                formData.append("password", this.decrypt_password)

                const resp = await fetch("/api/storage_encryption_key_decrypted",
                    {method: "POST", credentials: "include",
                     body: formData });
                
                const jdata = await resp.json();

                if(jdata.ok && jdata.decrypt_err)
                    jdata.err="Error decrypting key. Password might be wrong.";
                
                if("err" in jdata
                    || !jdata.ok)
                {
                    if(!("err" in jdata))
                        jdata.err="Unknown error";

                    Modal.warn(
                    {
                        title: "Error while decrypting password",
                        content: (
                            <p>{jdata.err}</p>
                        ),
                        onOk: () => {}
                    });
                    if(jdata.err==="No session") {
                        this.props.switchToLogin();
                        this.props.history.push("/app/");
                    }
                    this.setRevealLoading(record, false);
                    return;
                }

                let data = [ ...this.state.data];

                record.enckey=jdata.enckey;
                record.decrypt_loading=false;
                data = data.map( (v: TableData) => {
                    if (v.id === record.id)
                        return record;
                    else
                        return v;
                });
                this.setState({data: data});
            },
            onCancel: () => {
                this.setRevealLoading(record, false);
            }
        })
    }

    delEnckey = (record: TableData) => {
        Modal.confirm({
            title: "Confirm deletion",
            icon: <ExclamationCircleOutlined />,
            content: ("Are you sure you really want to encryption key "+record.disk_id+" created on "+new Date(record.created*1000).toLocaleString()+"?"),
            onOk: async () => {
                var formData = new FormData();
                formData.append("disk_id", record.disk_id);

                let jdata;
                try {
                    const resp = await fetch("/api/storage_encryption_key",
                        {method: "DELETE", credentials: "include",
                        body: formData });

                    jdata = await resp.json();
                } catch(error) {
                    jdata = {"err": "Connection issue with service"};
                }

                if("err" in jdata
                    || !jdata.ok)
                {
                    if(!("err" in jdata))
                        jdata.err="Unknown error";

                    Modal.warn(
                    {
                        title: "Error while deleting encryption key",
                        content: (
                            <p>{jdata.err}</p>
                        ),
                        onOk: () => {}
                    });
                    if(jdata.err==="No session") {
                        this.props.switchToLogin();
                        this.props.history.push("/app/");
                    }
                    return;
                }

                let data = [ ...this.state.data];
                data = data.filter( (v: TableData) => {
                    return v.id!==record.id;
                });
                this.setState({data: data});
                message.info("Encryption key successfully deleted");
            }
        });
    }

    columns = [
        {
          title: 'Id',
          dataIndex: 'id',
          sorter: (a: TableData, b: TableData) => a.id-b.id,
        },
        {
            title: 'Disk name',
            dataIndex: 'disk_id',
            sorter: (a: TableData, b: TableData) => a.disk_id.localeCompare(b.disk_id),
        },
        {
          title: 'Created at',
          dataIndex: 'created',
          defaultSortOrder: this.descendSortOrder(),
          render: (created: number) => new Date(created*1000).toLocaleString(), 
          sorter: (a: TableData, b: TableData) => a.created-b.created,
        },
        {
            title: "Encryption key",
            render: (text: string, record: TableData) => {
                if(record.enckey.length>0)
                    return record.enckey;

                return (<span>
                        <Button onClick={(e) => {this.decryptEnckey(record)}} loading={record.decrypt_loading}>Decrypt</Button>
                        <Button danger onClick={(e) => {this.delEnckey(record)}} style={{marginLeft: "5pt"}}>Delete</Button>
                        </span>)
            }
        }
      ];

    render() {
        return (
            <div style={{ height: "100%"}}>
                <Table
                    columns={this.columns}
                    rowKey={record => record.id.toString()}
                    dataSource={this.state.data}
                    loading={this.state.loading}
                    pagination={{position: "both", showSizeChanger: true, defaultPageSize: 50}}
                    title={() => "Uploaded disk encryption keys"}/>
            </div>
        );
    }
}

export default withRouter(connector(Keys))