import React, {Component} from 'react';
import { State } from "./reducer";
import { Dispatch } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { Modal, Table, Pagination, Form, Button, message } from 'antd';
import { PaginationProps, PaginationConfig } from 'antd/lib/pagination';
import { Key, SorterResult, SortOrder } from 'antd/lib/table/interface';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { SWITCH_MENU_STATE } from './actions';
import { RouteComponentProps, withRouter } from 'react-router-dom';


const mapStateToProps = (state: State) => {
    return {

    }
}
  
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        switchToLogin: () => dispatch({type: SWITCH_MENU_STATE, newState: ""})
    }
}

function humanFileSize(bytes: number, si: boolean) {
    if(bytes==null) {
        return "0 B";
    }

    let thresh = si ? 1000 : 1024;
    if(Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    let units = si
        ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
        : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while(Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1)+' '+units[u];
}

function format_time_seconds(t: number, s: boolean)
{
	var x_min=60;
	var x_hour=x_min*60;
	var x_day=x_hour*24;
	var x_week=x_day*7;
	var x_month=x_week*4.345;
    var x_year=x_month*12;
    
    interface XTP {
        year: number,
        month: number,
        week: number,
        day: number,
        hour: number,
        min: number
    }

    let x_tp = [x_year, x_month, x_week, x_day, x_hour, x_min];
    let x_tp_name = ["year", "month", "week", "day", "hour", "min"];
	
	var neg=false;
	if(t<0)
	{
		neg=true;
		t*=-1;
	}

    let ret="";
	for(let i=0;i<x_tp.length;i+=1)
	{
        let x=x_tp_name[i];
		let y=Math.floor(t/x_tp[i]);
		if(y>0)
		{
			if(ret!=="")
				ret+=" ";
				
			if(neg)
				ret+="-";
				
			ret+=y;
			var c_s=(s===true);
			if(c_s)
			{
				if(x==="hour") ret+="h";
				else if(x==="min") ret+="m";
				else c_s=false;
			}
			
			if(!c_s)
			{
				ret+=" ";
				if(y>1)
					ret+=x+"s";
				else
					ret+=x;
			}
			
				
			t-=x_tp[i]*y;
		}
	}
	
	return ret;
}

const connector = connect(mapStateToProps, mapDispatchToProps);

interface FetchParams 
{
    results?: number;
    start?: number;
    sortField?: string;
    sortOrder?: SortOrder;
}

interface TableData
{
    id: number,
    cdname: string,
    created: number,
    appname: string,
    size: number,
    todel: number,
    startdel: number,
    del_loading: boolean
}

interface TableState {
    data: Array<TableData>,
    pagination: PaginationConfig,
    loading: boolean
}

class Clouddrives extends Component<RouteComponentProps & ConnectedProps<typeof connector> >
{
    state: TableState = {
        data: [],
        pagination: {
            position: "both",
            showSizeChanger: true,
            defaultPageSize: 50
        },
        loading: false,
      };

    componentDidMount() {
        this.fetchData({});
    }

    handleTableChange = (pagination: PaginationProps, filters: Record<string, Key[] | null>, sorter: SorterResult<TableData> | SorterResult<TableData>[]) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
          pagination: pager,
        });

        let isSingleSorter = (p: any): p is SorterResult<TableData> => !!p.field;
        let isMultipleSorters = (p: any): p is SorterResult<TableData> => !!p.length;

        if(typeof pagination.pageSize!="undefined"
            && typeof pagination.current!="undefined")
        {
            if(isSingleSorter(sorter)) {
                this.fetchData({
                    
                    results: pagination.pageSize,
                    start: (pagination.current-1)*pagination.pageSize,
                    sortField: typeof sorter.field == "undefined" ? undefined : ("" + sorter.field),
                    sortOrder: sorter.order,
                    ...filters,
                    });
            } else if(!isMultipleSorters(sorter)) {
                this.fetchData({                
                    results: pagination.pageSize,
                    start: (pagination.current-1)*pagination.pageSize,
                    ...filters,
                    });            
            }
        }
      };

    requestParams = (params: FetchParams) => {
        let fd = new FormData();
        if(typeof params.results!="undefined")
            fd.append("results", "" + params.results);
        if(typeof params.start!="undefined")
            fd.append("start", ""+params.start);
        if(typeof params.sortField!="undefined")
            fd.append("sortField", params.sortField);
        if(typeof params.sortOrder!="undefined")
            fd.append("sortOrder", "" + params.sortOrder);
        return fd;
    }

    fetchData = async (params: FetchParams) => {
        this.setState({loading: true});

        const resp = await fetch("/api/get_clouddrives2",
            {method: "POST", credentials: "include",
             body: this.requestParams(params)});
        
        const jdata = await resp.json();
        
        if("err" in jdata
            || !jdata.ok)
        {
            if(!("err" in jdata))
                jdata.err="Unknown error";
            Modal.info(
            {
                title: "Error while fetching data",
                content: (
                    <p>{jdata.err}</p>
                ),
                onOk: () => {}
            });
            if(jdata.err==="No session") {
                this.props.switchToLogin();
                this.props.history.push("/app/");
            }
            return;
        }

        const pagination = { ...this.state.pagination };
        pagination.total = jdata.total;

        this.setState({
            loading: false,
            data: jdata.cds,
            pagination
        })
    }

    setDelLoading = (record: TableData, b: boolean) => {
        let data = [ ...this.state.data];

        record.del_loading=b;
        data = data.map( (v: TableData) => {
            if (v.id === record.id)
                return record;
            else
                return v;
        });
        this.setState({data: data});
    }

    deleteClouddrive = async (record: TableData) => {
        Modal.confirm({
            title: "Confirm deletion",
            icon: <ExclamationCircleOutlined />,
            content: ("Are you sure you really want to delete cloud drive "+record.cdname+" from app "+record.appname+" created on "+new Date(record.created*1000).toLocaleString()+" ?"),
            onOk: async () => {
                this.setDelLoading(record, true);
                let fd = new FormData()
                fd.append("clouddrive_id", ""+record.id)
                const resp = await fetch("/api/del_clouddrive",
                    {method: "POST", credentials: "include",
                    body: fd})

                const jdata = await resp.json();

                if("err" in jdata)
                {
                    Modal.info(
                    {
                        title: "Error while starting cloud drive deletion",
                        content: (
                            <p>{jdata.err}</p>
                        ),
                        onOk: () => {}
                    });
                    this.setDelLoading(record, false);
                    return;
                }

                message.info("Successfully scheduled deletion of cloud drive");

                let data = [ ...this.state.data];

                record.startdel = (Date.now() / 1000)+60*60*24*7;
                record.del_loading=false;
                data = data.map( (v: TableData) => {
                    if (v.id === record.id)
                        return record;
                    else
                        return v;
                });
                this.setState({data: data});
            }
        });
    }

    stopDeletion = async (record: TableData) => {
        this.setDelLoading(record, true);
        let fd = new FormData()
        fd.append("clouddrive_id", ""+record.id)
        const resp = await fetch("/api/stop_del_clouddrive",
                    {method: "POST", credentials: "include",
                    body: fd})

        const jdata = await resp.json();

        if("err" in jdata)
        {
            Modal.info(
            {
                title: "Error while stopping cloud drive deletion",
                content: (
                    <p>{jdata.err}</p>
                ),
                onOk: () => {}
            });
            this.setDelLoading(record, false);
            return;
        }

        let data = [ ...this.state.data];

        record.startdel=0;
        record.del_loading=false;
        data = data.map( (v: TableData) => {
            if (v.id === record.id)
                return record;
            else
                return v;
        });
        this.setState({data: data});
    }

    columns = [
        {
          title: 'Id',
          dataIndex: 'id',
          sorter: true,
        },
        {
          title: 'Cloud drive name',
          dataIndex: 'cdname',
          sorter: true,
        },
        {
          title: 'Created',
          dataIndex: 'created',
          render: (created: number) => new Date(created*1000).toLocaleString(),
          sorter: true,
        },
        {
            title: 'Appliance name',
            dataIndex: 'appname',
            sorter: true,
        },
        {
            title: 'Max size',
            dataIndex: 'max_size',
            render: (size: number) => humanFileSize(size, false),
            sorter: true,
        },
        {
            title: 'Used size',
            dataIndex: 'used_size',
            render: (size: number) => humanFileSize(size, false),
            sorter: true,
        },
        {
            title: "Last updated",
            dataIndex: "last_update",
            render: (created: number) => new Date(created*1000).toLocaleString(),
            sorter: true
        },
        {
            title: "Last accessed",
            dataIndex: "atime",
            render: (atime: number) => new Date(atime*1000).toLocaleString(),
            sorter: true
        },
        {
            title: "Actions",
            render: (text: string, record: TableData) => {

                let delBtn: JSX.Element;
                if (record.startdel>0)
                {
                    delBtn = <span>
                        Will be deleted in {format_time_seconds(record.startdel-(Date.now() / 1000), false)}
                        <Button onClick={(e) => {this.stopDeletion(record)}} loading={record.del_loading} style={{marginLeft: "5pt"}}>Stop deletion</Button>
                    </span>
                }
                else
                {
                    delBtn = <Button danger onClick={(e) => {this.deleteClouddrive(record)}} loading={record.del_loading}>Delete</Button>;
                }

                return (
                    <span>
                    { record.todel===0 &&
                        delBtn
                    }
                    { record.todel!==0 &&
                        <p>Currently being deleted</p>    
                    }
                    </span>
                )
            }
        }
      ];


    render() {
        return (
            <div style={{ height: "100%"}}>
                <Table
                    columns={this.columns}
                    rowKey={record => record.id.toString()}
                    dataSource={this.state.data}
                    pagination={this.state.pagination}
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                    title={ () => "Cloud drives" }/>
            </div>
        );
    }
}

export default withRouter(connector(Clouddrives))